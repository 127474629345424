import React from 'react';
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import cover from '../assets/cover.jpg';
import {captureLink} from "../analytics";

function Top() {
    return (
        <section className="hero-section">
            <Container className="container">
                <Row className="row">
                    <Col className="col-12 col-md-7 pt-5 mb-5 align-self-center">
                        <div className="promo pr-md-3 pr-lg-5">
                            <h1 style={{fontFamily: 'Dancing Script'}}>Topics of Heavenly Proportions</h1>
                        </div>
                        <div className="subheadline mb-4">Topics of Heavenly Proportions was written as an inspired book by the Holy Spirit. In this book, the author speaks about many different topics that concern people today.</div>
                        <div className="cta-holder">
                            <Button variant="primary" large onClick={() => captureLink('https://checkout.square.site/merchant/ML7232HNJPG9H/checkout/K75G4F3IK7KP22AGR2DM25X3', 'square')}>Buy Print</Button>
                        </div>
                        <div className="cta-holder mt-4">
                            <Button variant="primary" large onClick={() => captureLink('https://www.amazon.com/Topics-Heavenly-Proportions-Diane-Lyons-Frasco-ebook/dp/B0DP5HZ4KH/ref=sr_1_1?dib=eyJ2IjoiMSJ9.blmmBgaDcU9x2bTt1x37_3Pw_J509lkFnfJcR9PO8RRF6xR-zF7CX1Bfhgi1kbCf.JnX0G4gcg9h0wQegdeeuiZS8-E4DzDMKf_7mxclGLK4&dib_tag=se&keywords=Topics+of+Heavenly+Proportions&qid=1740860768&s=amazon-devices&sr=1-1', 'amazon')}>Buy eBook</Button>
                        </div>
                    </Col>
                    <Col className="col-12 col-md-5 mb-5 align-self-center">
                        <div className="book-cover-holder" style={{marginTop: '4rem'}}>
                            <img className="img-fluid book-cover" src={cover}
                                 alt="book cover"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

    );
}

export default Top;
