import React from 'react';
import {captureLink} from "../analytics";
import {Button} from "react-bootstrap";

function Who() {
    return (
        <section id="audience-section" className="audience-section py-5">
            <div className="container">
                <h2 className="section-heading text-center mb-4"
                    style={{fontFamily: 'Dancing Script'}}>Who This Book Is
                    For</h2>
                <div
                    className="section-intro single-col-max mx-auto text-center mb-5">
                    This book is written for everyone. It was written in a
                    simple manner to appeal, especially for the youth. The Holy
                    Spirit welcomes all souls to read it and enjoy it.
                </div>
                <div className="text-center">
                <div className="cta-holder">
                    <Button variant="primary" large
                            onClick={() => captureLink('https://checkout.square.site/merchant/ML7232HNJPG9H/checkout/K75G4F3IK7KP22AGR2DM25X3', 'square')}>Buy
                        Print</Button>
                </div>
                <div className="cta-holder mt-4">
                    <Button variant="primary" large
                            onClick={() => captureLink('https://www.amazon.com/Topics-Heavenly-Proportions-Diane-Lyons-Frasco-ebook/dp/B0DP5HZ4KH/ref=sr_1_1?dib=eyJ2IjoiMSJ9.blmmBgaDcU9x2bTt1x37_3Pw_J509lkFnfJcR9PO8RRF6xR-zF7CX1Bfhgi1kbCf.JnX0G4gcg9h0wQegdeeuiZS8-E4DzDMKf_7mxclGLK4&dib_tag=se&keywords=Topics+of+Heavenly+Proportions&qid=1740860768&s=amazon-devices&sr=1-1', 'amazon')}>Buy
                        eBook</Button>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Who;
